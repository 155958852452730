<template>
  <!-- <Transition name="slide-fade" mode="out-in"> -->
  <router-view />
  <!-- </Transition> -->
</template>

<script>
import { useUserStore } from "./store/UserStore";
//import { onMounted } from "vue";
import router from "@/router";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    };
  },

  beforeCreate() {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.userStore.user = user;
        if (router.currentRoute.value.path === "/signin") {
          router.push("/orders");
        }
        router.push("/orders");
      } else {
        // User is signed out, go to home /
        router.push("/");
      }
    });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200&family=Red+Hat+Mono:wght@300;400;500;600;700&family=Yomogi&display=swap");
@import url("@/assets/styles/global.css");

/* ** TRANSITIONS ** */
.slide-fade-enter-from {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
