import { defineStore } from "pinia";

import router from "@/router";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db, ordersRef } from "@/firebase";
import {
  getDoc,
  getDocs,
  increment,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    connectionStatus: true,
    user: "",
    orderToCreate: {
      // Sales
      storeID: "Local1",
      salesID: "",

      // Order
      number: 0,
      status: 0,
      priority: 0,
      id: "",

      // Dates
      time: "",
      date: "",
      year: 0,
      month: 0,
      day: 0,

      dateEstimatedReady: "",
      dateShipping: "",

      apptInstall: true,
      apptInstallScheduled: false,
      dateInstall: "",
      apptInstallStart: "",
      apptInstallEnd: "",

      apptPatterns: false,
      apptPatternsScheduled: false,
      datePatterns: "",
      apptPatternsStart: "",
      apptPatternsEnd: "",

      // Shipping
      shippingAddress: "",
      shippingCarrier: "",

      // Customer
      cxNumber: 0,
      cxName: "",
      cxEmail: "",
      cxPhoneMobile: "",
      cxPhoneLandLine: "",

      //Cubreasientos
      setSKU: "",
      setMake: "",
      setModel: "",
      setYear: "",
      setVersion: "",

      setWrapMaterial: "",
      setWrapColor: "",
      setWrapPattern: "",
      setWrapDescription: "",

      setAccentMaterial: "",
      setAccentColor: "",
      setAccentPattern: "",
      setAccentDescription: "",

      setWingMaterial: "",
      setWingColor: "",
      setWingPattern: "",
      setWingDescription: "",

      setStitching: "",
      setEmbroidery: "",
      setEmbroideryCustom: "",

      quantity: 1,

      // Billing
      billingOnOff: false, // true / false
      billingName: "",
      billingRFC: "",
      billingAddress: "",
      billingUse: "",
      billingPaymentMethod: "",

      cost: 0,
      downPayment: 0,

      notes: "",
    },
    orderToRetrieve: {
      // Sales
      storeID: "Local1",
      salesID: "",

      // Order
      number: 0,
      status: 0,
      priority: 0,
      id: "",

      // Dates
      time: "",
      date: "",
      year: 0,
      month: 0,
      day: 0,

      dateEstimatedReady: "",
      dateShipping: "",

      apptInstall: false,
      apptInstallScheduled: false,
      dateInstall: "",
      apptInstallStart: "",
      apptInstallEnd: "",

      apptPatterns: false,
      apptPatternsScheduled: false,
      datePatterns: "",
      apptPatternsStart: "",
      apptPatternsEnd: "",

      // Shipping
      shippingAddress: "",
      shippingCarrier: "",

      // Customer
      cxNumber: 0,
      cxName: "",
      cxEmail: "",
      cxPhoneMobile: "",
      cxPhoneLandLine: "",

      //Cubreasientos
      setSKU: "",
      setMake: "",
      setModel: "",
      setYear: "",
      setVersion: "",

      setWrapMaterial: "",
      setWrapColor: "",
      setWrapPattern: "",
      setWrapDescription: "",

      setAccentMaterial: "",
      setAccentColor: "",
      setAccentPattern: "",
      setAccentDescription: "",

      setWingMaterial: "",
      setWingColor: "",
      setWingPattern: "",
      setWingDescription: "",

      setStitching: "",
      setEmbroidery: "",
      setEmbroideryCustom: "",

      quantity: 1,

      // Billing
      billingOnOff: false, // true / false
      billingName: "",
      billingRFC: "",
      billingAddress: "",
      billingUse: "",
      billingPaymentMethod: "",

      cost: 0,
      downPayment: 0,

      notes: "",
    },
    allOrders: [],
    allOrdersFiltered: null,
    filterOptions: {
      status: "",
      day: 7,
      name: "",
      make: "",
      model: "",
      year: 0,
      email: "",
      searchString: "",
    },
    showLoading: false,
    editMode: true, // true=edit, false=view only
    showForm: false,
  }),
  getters: {
    totalOrders() {
      return this.allOrders.length;
    },
    //numberOfOrders: (state) => state.allOrders.length,
  },
  actions: {
    async login(details) {
      const { email, password } = details;

      try {
        await signInWithEmailAndPassword(auth, email, password);

        this.user = auth.currentUser;

        router.push("/orders");
      } catch (error) {
        alert("Informacion incorrecta o incompleta!");
      }
    },
    async logout() {
      await signOut(auth);
      this.user = null;
      router.push("/signin");
    },
    async fetchAllOrders() {
      this.showLoading = true;

      // Status filter
      this.allOrders = [];

      const q = await getDocs(ordersRef);

      q.forEach((doc) => {
        //if (this.filterOptions.status == "") {
        this.allOrders.push(doc.data());
      });

      // Status
      if (this.filterOptions.status != "") {
        this.allOrders = this.allOrders.filter((order) => {
          return order.status == this.filterOptions.status;
        });
      }

      // Folio, make, model, phone, name, notes
      if (this.filterOptions.searchString != "") {
        this.allOrders = this.allOrders.filter((order) => {
          return (
            order.number.toString().search(this.filterOptions.searchString) >=
              0 ||
            order.setMake
              .toLowerCase()
              .search(this.filterOptions.searchString.toLowerCase()) >= 0 ||
            order.setModel
              .toLowerCase()
              .search(this.filterOptions.searchString.toLowerCase()) >= 0 ||
            order.cxPhoneMobile
              .toLowerCase()
              .search(this.filterOptions.searchString.toLowerCase()) >= 0 ||
            order.cxName
              .toLowerCase()
              .search(this.filterOptions.searchString.toLowerCase()) >= 0 ||
            order.notes
              .toLowerCase()
              .search(this.filterOptions.searchString.toLowerCase()) >= 0
          );
        });
      }

      this.allOrders.sort((a, b) => b.number - a.number);

      this.showLoading = false;
    },
    async createOrder() {
      //*********************pending to reset form fields when creating new

      /* await setDoc(doc(db, "settings", "invoiceSeries"), {
        number: 2999,
      }); */

      // Increment invoice series
      const settingsSeriesIncrementRef = doc(db, "settings", "invoiceSeries");
      await updateDoc(settingsSeriesIncrementRef, {
        number: increment(1),
      });

      // Get next invoice series
      const settingsSnap = await getDoc(settingsSeriesIncrementRef);
      if (settingsSnap.exists()) {
        //console.log("get next invoice #: ", settingsSnap.data().number);
        this.orderToCreate.number = settingsSnap.data().number;
      } else {
        console.log("No such document!");
      }

      // Create date
      let today = new Date(Date.now());
      this.orderToCreate.time = today.toLocaleTimeString();
      this.orderToCreate.date = today.toLocaleDateString("es-MX", {
        dateStyle: "full",
      });
      this.orderToCreate.year = today.getFullYear();
      this.orderToCreate.month = today.getMonth() + 1;
      this.orderToCreate.day = today.getDate();

      // Create draft order with just the order number
      try {
        const docRef = doc(ordersRef);

        // Get and Set id
        this.orderToCreate.id = docRef.id;

        // Data to populate
        await setDoc(docRef, this.orderToCreate);

        // Retrieve just created and show in edit mode
        this.fetchOrder(docRef.id);
      } catch (e) {
        console.log("error creating: ", e);
      }
    },
    async fetchOrder(selectedID) {
      this.showLoading = true;

      const justCreatedRef = doc(db, "orders", selectedID);

      const justCreatedSnap = await getDoc(justCreatedRef);
      this.orderToRetrieve = justCreatedSnap.data();

      this.showLoading = false;

      if (this.orderToRetrieve.status == 0) {
        this.editMode = true;
      } else {
        this.editMode = false;
      }

      this.showForm = true;
    },
  },
});
