import { createRouter, createWebHistory } from "vue-router";
import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/OrdersView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agenda",
    name: "agenda",
    component: () => import("@/views/AgendaView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/produccion",
    name: "produccion",
    component: () => import("@/views/ProductionView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/instalaciones",
    name: "instalaciones",
    component: () => import("@/views/AgendaInstalls.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reviews",
    name: "Reseñas",
    component: () => import("@/views/ReviewsManager.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/convos",
    name: "convos",
    component: () => import("@/views/ConvoManager.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import("@/views/FAQs.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/BlogPage.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/SigninPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/signin" && auth.currentUser) {
    next("/orders");
  } else {
    next();
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !auth.currentUser
  ) {
    next("/signin");
  }
});

export default router;
