import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBHMMlyCfbojxpeC1NhB-eNZsTI5-8GZ28",
  authDomain: "cubreasientosalamedida-8e8ee.firebaseapp.com",
  projectId: "cubreasientosalamedida-8e8ee",
  storageBucket: "cubreasientosalamedida-8e8ee.appspot.com",
  messagingSenderId: "464613639452",
  appId: "1:464613639452:web:adaccb68ea3a8afe11a6d9",
  measurementId: "G-C2DRHFCZZV",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

const ordersRef = collection(db, "orders");
const settingsColRef = collection(db, "settings");
const chatsColRef = collection(db, "chats");
const reviewsColRef = collection(db, "reviews");

export {
  auth,
  onAuthStateChanged,
  ordersRef,
  settingsColRef,
  chatsColRef,
  reviewsColRef,
  db,
};
